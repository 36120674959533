<template>
  <div class="password-reset">
    <div class="password-reset__wrapper">
      <div class="password-reset__logo">
        <div class="password-reset__logo-icon">
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" fill="#7E93FF" />
            <path
              d="M9.8999 15.3999V17.5999C9.8999 22.46 13.8398 26.3999 18.6999 26.3999H30.2499"
              stroke="white"
              stroke-width="2.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.1 13.2001H18.7C16.27 13.2001 14.3 15.1701 14.3 17.6001V17.6001C14.3 20.0302 16.27 22.0001 18.7 22.0001H29.7C32.1301 22.0001 34.1 23.9701 34.1 26.4001V26.4001C34.1 28.8302 32.1301 30.8001 29.7 30.8001H23.1"
              stroke="white"
              stroke-width="2.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="password-reset__logo-text">LobbySpace</div>
      </div>
      <h1 class="password-reset__title">
        {{ $t("pages.passwordReset.title") }}
      </h1>
      <Card class="password-reset__card">
        <form class="password-reset__form" @submit.prevent="resetPassword">
          <label
            class="password-reset__label text__small--bold"
            for="password-reset__input-email"
            >{{ $t("pages.login.emailLabel") }}</label
          >
          <input
            class="password-reset__input"
            v-model.trim="email"
            type="text"
            placeholder="you@email.com"
            id="password-reset__input-email"
          />

          <Button type="submit" class="password-reset__button--login">
            {{ $t("pages.passwordReset.resetBtn") }}
          </Button>
        </form>
        <div
          v-if="feedback !== ''"
          class="password-reset__error text__small--regular"
        >
          {{ feedback }}
        </div>
        <div
          v-if="successMsg !== ''"
          class="password-reset__success text__small--regular"
        >
          {{ successMsg }}
        </div>
      </Card>

      <Card class="password-reset__card">
        <div class="password-reset__card-title">
          {{ $t("pages.passwordReset.remembered") }}
        </div>
        <Button
          theme="secondary"
          class="password-reset__button--register"
          @click="goToLogin"
        >
          {{ $t("pages.passwordReset.backToLoginBtn") }}
        </Button>
      </Card>

      <div class="password-reset__further-info">
        <router-link to="/imprint" class="password-reset__further-info-link">
          {{ $t("pages.login.imprint") }}
        </router-link>
        <span>|</span>
        <router-link
          to="/terms-of-use"
          class="password-reset__further-info-link"
        >
          {{ $t("pages.login.termsOfUse") }}
        </router-link>
        <span>|</span>
        <router-link
          to="/data-privacy"
          class="password-reset__further-info-link"
        >
          {{ $t("pages.login.dataPrivacy") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import Button from "@/components/Button";
import Card from "@/components/Card";

export default {
  components: {
    Button,
    Card
  },
  data() {
    return {
      email: "",
      successMsg: "",
      feedback: ""
    };
  },
  methods: {
    resetPassword() {
      if (this.email) {
        this.feedback = "";

        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(() => {
            this.successMsg = this.$t("pages.passwordReset.successfulMsg");
          })
          .catch(error => {
            this.feedback = error.message;
          });
      } else {
        this.feedback = this.$t("pages.passwordReset.fillAllFields");
      }
    },
    goToLogin() {
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style lang="scss">
.password-reset {
  min-height: 100vh;
  padding-top: 42px;
  @apply flex justify-center bg-ls-gray-100;

  &__wrapper {
    @apply px-3 w-full flex flex-col;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      margin-left: 8px;
    }
  }

  &__title {
    text-align: center;
    margin: 57px 0 36px;
    font-size: 48px;
    font-weight: 600;
  }

  &__card {
    @apply flex flex-col p-6 mb-6;

    &-title {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__card-sso-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &__form {
    @apply flex flex-col;
  }

  &__label {
    margin-bottom: 4px;
  }

  &__input {
    height: 42px;
    @apply rounded-lg px-2 bg-ls-gray-100 mb-4;
  }

  &__button--register {
    width: fit-content;
    margin: 16px auto 0;
  }

  &__error {
    @apply text-red-600 mt-2;
  }

  &__success {
    @apply text-green-600 mt-2;
  }

  &__further-info {
    margin: 0 auto;
    @apply flex text-xs text-ls-gray-800;

    span {
      @apply mx-3;
    }
  }
}

@media screen and (min-width: 900px) {
  .password-reset {
    &__wrapper {
      @apply px-0 w-auto;
    }

    &__card {
      width: 540px;
    }
  }
}
</style>
